import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '~assets/scss/main.scss'

const Layout = ({ children }) => {

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'falcon.code, software made with speed and precision' },
              { name: 'keywords', content: 'software, programming, javascript, css, web app, web application, progressive web app, consulting, contract, custom software, software solutions' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <div>
            {children}
          </div>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
